import {websocketMessageReceived, websocketConnected, websocketDisconnected} from '../redux/WebSocket';

export const setupWebSocket = (store: any, clientId: any, connected: boolean) => {

    const ws = new WebSocket('wss://endpoint.pourtertech.com/ws');
    store.dispatch(websocketConnected());

    ws.onopen = () => {
        console.log('Connected to server');
        ws.send(JSON.stringify({
            type: 'register',
            id: clientId
        }));
    };

    ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        store.dispatch(websocketMessageReceived(message));
    };

    ws.onclose = () => {
        console.log('Disconnected from server');
        store.dispatch(websocketDisconnected());
    };

    ws.onerror = (error) => {
        console.error('WebSocket error:', error);
    };

    return ws;


};
