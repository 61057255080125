import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import {Store} from './Store'
import {Provider} from 'react-redux'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={Store}>
        <App/>
    </Provider>
);

serviceWorkerRegistration.register();

reportWebVitals();
