import React, { ReactNode } from "react";

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  children: ReactNode;
  onClick: () => void;
  className?: string;
  style: string;
}

const Button: React.FC<ButtonProps> = ({
  type,
  children,
  onClick,
  className,
  style
}) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={`
        ${
          style === "primary"
            ? "flex w-full pt-6 pb-6 items-center border justify-center rounded-lg bg-dark-blue max-h-11 px-5 py-2.5 text-md font-bold text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            : ""
        }
        ${
          style === "secondary"
          ? "flex w-full pt-6 pb-6 items-center border justify-center rounded-lg bg-white max-h-11 px-5 py-2.5 text-md font-bold text-dark-blue hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          : ""
        }
        ${className}`}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
