import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {RootState} from "../Store";
import { selectPour } from "../redux/Pour";
import { selectCart } from "../redux/Cart";
import Loader from "../components/Loader";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const data = useSelector((state: RootState) => state);

  const clickMe =
    (parameter: { id: null; name: ""; price: 0; qty: 0; unit: "" }) => () => {
      dispatch(selectPour(parameter));
      navigate("/cart/" + id + "/pour/" + parameter.id);
    };

  useEffect(() => {
    if (data.cart.list.length > 0) {
      let detail = data.cart.list.find(
        (cartList: { id: any }) => cartList.id == id
      );
      dispatch(selectCart(detail));
    }
  });

  let logoMap = [
    "",
    "cocacola.png",
    "fanta.png",
    "sprite.png",
    "7up.png",
    "sunkist.png",
    "sierramist.png",
    "mtndew.png",
    "dietcoke.png",
    "pepsi.png",
    "drpepper.png",
  ];

  return (
    <>
      <div className="flex flex-col p-6">
        <h4 className="text-2xl mb-6 font-semibold text-gray-900 ">Products</h4>
        <div className="grid grid-cols-2 grid-rows-3 gap-4">
          {data.pour.list.length > 0 &&
            data.cart.detail.id &&
            data.pour.list.map((pour: any, index: number) => {
              if (data.cart.detail.id == pour.cart_id) {
                return (
                  <a
                    href=""
                    className="flex flex-col items-center bg-gray-100 rounded-xl p-4"
                    onClick={clickMe(pour)}
                    key={index}
                  >
                    <div>
                      {pour.imageUrl ? (
                        <img
                          src={pour.imageUrl}
                          alt={pour.name}
                          style={{ width: "150px", height: "150px" }}
                        />
                      ) : (
                        // <div style={{ width: '150px', height: '150px', backgroundColor: 'gray', borderRadius: '100' }}>
                        //   Image Not Available
                        // </div>
                        <img
                          width="150"
                          src={"../img/products/logos/" + logoMap[pour.id]}
                          className=""
                        />
                      )}
                      <div className="leading-none">
                        <h2 className="text-gray-500 text-lg mt-2">
                          {pour.name}
                        </h2>
                        <h3 className="text-lg font-semibold">
                          ${pour.price}/fl oz
                        </h3>
                      </div>
                      {/* <h4>{pour.unit} fl oz</h4> */}
                    </div>
                  </a>
                );
              }
            })}

          {data.pour.list.length == 0 && (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
