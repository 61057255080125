import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
  InformationCircleIcon,
  HomeIcon,
  QrCodeIcon,
  DocumentTextIcon

} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { useLocation } from 'react-router-dom';



export default function Header() {
  const location = useLocation();
const hideComponent = location.pathname === '/cart/1/pour/1'; 

  return (
    <footer className="bg-gray-500 sticky px-14 py-4 z-50" style={{ display: hideComponent ? 'none' : 'block' }}>
      <div className='flex justify-between text-white'>
        <a href="/cart/1"><HomeIcon height={30}></HomeIcon></a>
        <a href="/"><QrCodeIcon height={30}></QrCodeIcon></a>
        <a href="/order"><DocumentTextIcon height={30}></DocumentTextIcon></a>
        <a href="/info"><InformationCircleIcon height={30}></InformationCircleIcon></a>
      </div>
    </footer>
  )
}