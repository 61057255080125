import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

export interface CartState {
    detail: any,
    list: any,
}

const initialState: CartState = {
    detail: [],
    list: [],
};

export const Cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        selectCart: (state, action: PayloadAction<any>) => {
            state.detail = action.payload;
        },
        setList: (state, action: PayloadAction<any>) => {
            state.list = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {selectCart, setList} = Cart.actions

export default Cart.reducer
