import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
export interface CartState {
    detail: any,
    list: any,

}

const initialState: CartState = {
    detail: [],
    list: [],
};

export const Pour = createSlice({
    name: 'pour',
    initialState,
    reducers: {
        selectPour: (state, action: PayloadAction<any>) => {
            state.detail = action.payload;
        },
        setList: (state, action: PayloadAction<any>) => {
            state.list = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setList,selectPour } = Pour.actions

export default Pour.reducer
