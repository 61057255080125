import React, { useEffect, useState } from "react";
import QrScanner from "qr-scanner";
import { useNavigate } from "react-router-dom";
import Button from "../Button";

function Qrscan() {
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [qrScanner, setQrScanner] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (qrScanner) {
      const video: any = document.getElementById("qr-video");
      const scanner: any = new QrScanner(
        video,
        (result: any) => setResult(result),
        (error: any) => setError(error)
      );

      scanner.start().then(() => {
        // updateFlashAvailability();
        // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
        // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
        // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
        // start the scanner earlier.
        QrScanner.listCameras(true).then((cameras) =>
          cameras.forEach((camera) => {
            const option = document.createElement("option");
            option.value = camera.id;
            option.text = camera.label;
            // camList.add(option);
          })
        );
      });

      if (result != "") {
        scanner.stop();
        setQrScanner(false);

        const appUrl = process.env.APP_URL as string;

        var link = result.replace(appUrl, "");
        navigate(link);
      }
    }
  }, [result, navigate, qrScanner]);

  const startScan = () => {
    setQrScanner(true);
  };

  return (
    <>
      {qrScanner && (
        <div id="video-container">
          {/* <p>Result: {result}</p> */}
          {/* <p>Error: {error}</p> */}
          <video id="qr-video" />
          {/* <div className="scan-region-highlight">
            <svg
              className="scan-region-highlight-svg"
              viewBox="0 0 238 238"
              preserveAspectRatio="none"
            >
              <path d="M31 2H10a8 8 0 0 0-8 8v21M207 2h21a8 8 0 0 1 8 8v21m0 176v21a8 8 0 0 1-8 8h-21m-176 0H10a8 8 0 0 1-8-8v-21" />
            </svg>
            <svg className="code-outline-highlight" preserveAspectRatio="none">
              <polygon></polygon>
            </svg>
            <svg className="code-outline-highlight" preserveAspectRatio="none">
              <polygon />
            </svg>
          </div> */}
        </div>
      )}

      {!qrScanner && (
        <div className="flex flex-col p-6 justify-end" style={{ height: 'calc(100vh - 128px)'}}>
          <Button style="primary" className="mb-0" type="button" onClick={startScan}>
            Scan QR Code
          </Button>
        </div>
      )}
    </>
  );
}

export default Qrscan;
