import { Elements } from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../Store";
import { setCart } from "../../redux/Order";
import Loader from "../Loader";

const stripePromise = loadStripe("pk_test_FohJgJMQ3neAd9oJchFBPEtT00DXummADB");

export default function App() {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (localStorage.getItem("order") && data.order.totalPrice == 0) {
      let order = localStorage.getItem("order");
      if (order === null) {
        order = "";
      }
      dispatch(setCart(JSON.parse(order)));
    }

    if (data.order.totalPrice > 0) {
      getPaymentInitent();
    }
  }, [data.order]);

  const getPaymentInitent = async () => {
    fetch(process.env.REACT_APP_API_URL + "/paymentIntent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ price: data.order.totalPrice }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  };

  const options = {
    clientSecret,
  };

  return (
    <div className="p-6">
      <div className="space-y-6">
        <h4 className="text-2xl font-semibold text-gray-900 ">
          Order summary
        </h4>
        {data.order.items &&
          data.order.items.map((employee: any, index: number) => {
            return (
              <>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-gray-500 ">
                        {employee.name} ({(employee.qty * employee.unitCal).toFixed(0)} {employee.unitName})
                      </dt>
                      <dd className="text-base font-medium text-gray-900 ">
                        ${employee.price.toFixed(2)}
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-gray-500 ">Tax {data.cart.detail.tax}% :{" "}</dt>
                      <dd className="text-base font-medium text-gray-900 ">
                        {data.order.totalTax.toFixed(2)}
                      </dd>
                    </dl>
                  </div>

                  {data.order.totalPrice > 0 && (
                    <>
                      <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                        <dt className="text-lg font-bold text-gray-900 ">
                          Total
                        </dt>
                        <dd className="text-lg font-bold text-gray-900 ">
                          ${data.order.totalPrice.toFixed(2)}
                        </dd>
                      </dl>
                    </>
                  )}
                </div>
              </>
            );
          })}
      </div>

      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm data={data} />
        </Elements>
      )}

      {!clientSecret && <Loader />}
    </div>
  );
}
