import React, { useEffect, useState } from "react";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../Store";
import Loader from "../components/Loader";

import { getData } from "../components/User/TransactionsData";
import Button from "../components/Button";
import { setupWebSocket } from "../components/Websocket";
import { Store } from "../Store";
import { websocketConnected } from "../redux/WebSocket";
import { Link } from "react-router-dom";

type Inputs = {
  qty: number;
};

const Order = () => {
  const dispatch = useDispatch();

  const data = useSelector((state: RootState) => state);
  const websocket = useSelector((state: RootState) => state.websocket);
  const clientId = localStorage.getItem("customer_id");

  let [isOpen, setIsOpen] = useState(false);
  let [isDialogLoader, setDialogLoader] = useState(false);
  let [message, setMessage] = useState(
    "Would you like to pour yourself a drink?"
  );
  let [empty, setEmpty] = useState(false);
  let [step, setStep] = useState(0);

  const startPour = async () => {
    const pour = {
      transaction_id: data.transaction.activeItem.id,
      qty: data.transaction.activeItem.qty,
      customer_id: clientId,
      pout_id: data.transaction.activeItem.pouts_id,
    };

    setDialogLoader(true);
    setMessage("Waiting for pour");

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/startPour`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pour),
      }).then((response) => {
        if (response.status === 200) {
          setDialogLoader(false);
          setStep(1);
        } else {
          return response.json();
        }
      });
      const result = await response.json();
      // Handle result if needed
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data.transaction.request === 0) {
      setEmpty(true);
    }

    if (!websocket.connected) {
      const ws = setupWebSocket(Store, clientId, websocket.connected);
      return () => ws.close();
    }
  }, [data.transaction]);

  useEffect(() => {
    if (websocket.last.data?.status) {
      if (websocket.connected) {
        setStep(websocket.last.data.status);
        setDialogLoader(false);

        switch (websocket.last.data.status) {
          case "OKToPour":
            setMessage("Ready to pour");
            setStep(2);
            break;
          case "PourCompleted":
            setMessage("Pour End");
            setStep(3);
            break;
          default:
            setMessage("Something is wrong!");
            setStep(99);
            break;
        }
      }
    }
  }, [websocket]);

  return (
    <>
      {empty == false && <Loader />}

      <div className="p-6">
        <div className="space-y-4">
          <h4 className="text-2xl font-semibold text-gray-900 ">
            Ordered items
          </h4>

          {empty == false && <Loader />}

          {data.transaction.activeItem.qty > 0 ? (
            <>

              <div className="flex flex-col bg-gray-100 rounded-xl p-4">
                <div className="pb-4">
                  <dl className="flex items-center justify-between gap-4 text-xl">
                    <div className="text-gray-900">
                      {data.transaction.activeItem.name}
                    </div>
                    <div className="text-gray-900 ">
                      {(
                        data.transaction.activeItem.qty *
                        data.transaction.activeItem.unitCal
                      ).toFixed(2)}{" "}
                      <strong>{data.transaction.activeItem.unitName}</strong>
                    </div>
                  </dl>
                </div>
                <Button
                  style="primary"
                  type="button"
                  onClick={() => setIsOpen(true)}
                >
                  Unlock the tap
                </Button>
              </div>
            </>
          ) : (
            <p className="text-base font-medium text-gray-700 text-center bg-gray-100 p-2 rounded-md">
              No active orders
            </p>
          )}

          <h4 className="text-2xl font-semibold text-gray-900 ">
            Order History
          </h4>

          {data.transaction.items.length > 0 ? (
            data.transaction.items.map((item: any, indx: number) => {

              const updatedAtDate = new Date(item.updated_at);

              // Format date to American-style date and time
              const formattedDate = updatedAtDate.toLocaleString("en-US", {
                month: "short", // Jan, Feb, Mar, etc.
                day: "numeric", // 1, 2, 3, etc.
                year: "numeric", // 2022, 2023, etc.
                hour: "numeric", // 12-hour format
                minute: "numeric", // Minutes
                hour12: true, // Use AM/PM
              });

              return (
                <div
                  key={indx}
                  className="flex flex-col bg-white border border-gray-300 rounded-xl p-4"
                >
                  <div className="space-y-2">
                    <div className="flex items-center justify-between gap-4">
                      <div className="text-gray-600 ">{item.name}</div>
                      <div className="text-base font-medium text-gray-600 ">
                        {(item.qtyUsed * item.unitCal).toFixed(2)}{" "}
                        <strong>{item.unitName}</strong> / {item.totalPrice} $
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-4">
                      <div className="text-gray-400 text-sm">
                        {formattedDate}
                      </div>
                      <div className="flex items-center font-medium text-sm text-linkBlue">
                        <a href="/">Download receipt </a>
                        <ArrowDownTrayIcon className="pl-1 h-4 w-4 text-linkBlue" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-base font-medium text-gray-700 text-center bg-gray-100 p-2 rounded-md">
              No past orders
            </p>
          )}
        </div>
      </div>
      <>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="relative z-50 "
        >
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black bg-opacity-50">
            <div className="relative p-6 w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {isDialogLoader == false && step == 0 && (
                  <div className="flex flex-col justify-between p-6 md:p-5 text-center">
                    <h3 className="text-xl font-normal">{message}</h3>
                    <div className="text-xl font-normal py-4">
                      {data.transaction.activeItem.name}{" "}
                      <strong>
                        {(
                          data.transaction.activeItem.qty *
                          data.transaction.activeItem.unitCal
                        ).toFixed(2)}{" "}
                        {data.transaction.activeItem.unitName}
                      </strong>
                    </div>
                    <div className="flex gap-4">
                      <Button
                        style="secondary"
                        type="button"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button style="primary" type="button" onClick={startPour}>
                        Start
                      </Button>
                    </div>
                  </div>
                )}

                {isDialogLoader == true && (
                  <div className="flex flex-col justify-between p-4 md:p-5 text-center h-30">
                    <Loader />
                  </div>
                )}

                {isDialogLoader == false && step == 1 && (
                  <div className="flex flex-col justify-between p-4 md:p-5 text-center h-30">
                    <h3 className="text-xl font-normal">
                      <Loader />
                      {message}
                    </h3>
                  </div>
                )}

                {isDialogLoader == false && step == 2 && (
                  <div>
                    <div className="flex flex-col justify-between p-4 md:p-5 text-center h-30">
                      <h3 className="text-xl font-normal">
                        <Loader />
                        {message}
                      </h3>
                    </div>
                  </div>
                )}

                {isDialogLoader == false && step == 3 && (
                  <div>
                    <div className="flex flex-col justify-between p-4 md:p-5 text-center h-30">
                      <h3 className="text-xl font-normal">
                        {message}

                        <Button
                          style="secondary"
                          type="button"
                          onClick={() => setIsOpen(false)}
                        >
                          Cancel
                        </Button>
                      </h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </>
    </>
  );
};

export default Order;
