import React, { useState } from "react";

const Info = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full p-6">
      <div className="w-full">
        <div className="mb-6 lg:mb-16">
          <h6 className="text-lg text-center font-medium text-indigo-600 mb-1 lg:text-left">
            FAQ
          </h6>
          <h2 className="text-2xl text-center font-bold text-gray-900 leading-normal mb-5 lg:text-left">
            Looking for answers?
          </h2>
        </div>
        <div className="accordion-group">
          {accordionData.map((item, index) => (
            <div
              key={index}
              className={`accordion py-4 border-b border-solid border-gray-200 ${
                activeIndex === index ? "active" : ""
              }`}
            >
              <button
                className="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600"
                onClick={() => toggleAccordion(index)}
                aria-controls={`accordion-content-${index}`}
              >
                <h5>{item.question}</h5>
                <svg
                  className={`transition duration-500 ${
                    activeIndex === index ? "rotate-180" : ""
                  }`}
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              <div
                id={`accordion-content-${index}`}
                className={`accordion-content overflow-hidden transition-max-height duration-500 ease-in-out ${
                  activeIndex === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <p className="text-base font-normal text-gray-600">
                  {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const accordionData = [
  {
    question: "How to create an account?",
    answer:
      "To create an account, find the 'Sign up' or 'Create account' button, fill out the registration form with your personal information, and click 'Create account' or 'Sign up.' Verify your email address if needed, and then log in to start using the platform.",
  },
  {
    question: "Have any trust issue?",
    answer:
      "Our focus on providing robust and user-friendly content management capabilities ensures that you can manage your content with confidence, and achieve your content marketing goals with ease.",
  },
  {
    question: "How can I reset my password?",
    answer:
      "Our focus on providing robust and user-friendly content management capabilities ensures that you can manage your content with confidence, and achieve your content marketing goals with ease.",
  },
  {
    question: "What is the payment process?",
    answer:
      "Our focus on providing robust and user-friendly content management capabilities ensures that you can manage your content with confidence, and achieve your content marketing goals with ease.",
  },
];

export default Info;
