import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../Store";
import { selectPour } from "../redux/Pour";
import Loader from "../components/Loader";
import { selectCart } from "../redux/Cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaucet } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { addCart } from "../redux/Order";
import Button from "../components/Button";

type Inputs = {
  qty: number;
};

const Pour = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, pid } = useParams();
  const data = useSelector((state: RootState) => state);
  const { register, handleSubmit } = useForm<Inputs>();
  const [qty, setQty] = useState(1);

  const onSubmit = () => {
    let item = {
      id: data.pour.detail.id,
      name: data.pour.detail.name,
      price: data.pour.detail.price * qty,
      unitCal: data.pour.detail.unitCal,
      unitName: data.pour.detail.unitName,
      taxPrice:
        data.pour.detail.price * qty * (1 + data.cart.detail.tax / 100) -
        data.pour.detail.price * qty,
      qty: qty,
    };

    dispatch(addCart(item));
    navigate("/stripe");
  };

  const QtyMinus = () => {
    let newQty = qty - 1;
    if (newQty < 1) newQty = 1;
    setQty(newQty);
  };

  const QtyPlus = () => {
    let newQty = qty + 1;
    setQty(newQty);
  };

  useEffect(() => {
    if (data.cart.list.length > 0) {
      let detail = data.cart.list.find(
        (cartList: { id: any }) => cartList.id == id
      );
      dispatch(selectCart(detail));
    }

    if (data.pour.list.length > 0) {
      let detail = data.pour.list.find(
        (pourList: { id: any }) => pourList.id == pid
      );
      dispatch(selectPour(detail));
    }
  });

  let logoMap = [
    "",
    "cocacola.png",
    "fanta.png",
    "sprite.png",
    "7up.png",
    "sunkist.png",
    "sierramist.png",
    "mtndew.png",
    "dietcoke.png",
    "pepsi.png",
    "drpepper.png",
  ];

  return (
    <>
      {data.pour.detail.id && (
        <div key={pid} className="bg-gray-100">
          <div className="w-full flex justify-center p-6">
            <img
              width="150"
              src={"/img/products/logos/" + logoMap[data.pour.detail.id]}
              className=""
            />
          </div>
          <div className="bg-white w-full flex flex-col justify-center p-6 rounded-xl rounded-b-none">
            <div className="flex justify-between">
              <h4 className="text-xl font-bold">{data.pour.detail.name}</h4>
              <h4 className="text-xl font-normal">
                $ {data.pour.detail.price.toFixed(2)} /{" "}
                {parseFloat(data.pour.detail.unitCal)}{" "}
                {data.pour.detail.unitName}
              </h4>
            </div>
            <div className="w-full bg-white py-4">
              <form onSubmit={handleSubmit(onSubmit)} className="">
                <div className="flex justify-between pb-4">
                  <span
                    className="w-16 h-16 mt-3 text-2xl font-bold bg-dark-blue rounded-full flex items-center justify-center text-white"
                    onClick={QtyMinus}
                  >
                    -
                  </span>

                  <input
                    {...register("qty")}
                    type={"hidden"}
                    value={qty}
                    className="text-center max-w-10 text-xl font-bold"
                  />
                  <div>
                    <label className="block text-center max-w-50 text-2xl font-normal p-1">
                      {" "}
                      {parseFloat(data.pour.detail.unitCal) * qty}{" "}
                      {data.pour.detail.unitName}{" "}
                    </label>
                    <label className="block text-center max-w-50 text-2xl font-bold p-1">
                      {" "}
                      $ {(parseFloat(data.pour.detail.price) * qty).toFixed(
                        2
                      )}{" "}
                    </label>
                  </div>
                  <span
                    className="w-16 h-16 mt-3 text-2xl font-bold bg-dark-blue rounded-full flex items-center justify-center text-white"
                    onClick={QtyPlus}
                  >
                    +
                  </span>
                </div>
                <div className="mx-auto">
                  <Button
                    style="primary"
                    className="mt-0"
                    type="submit"
                    onClick={function (): void {
                      console.log("");
                    }}
                  >
                    {" "}
                    Check out
                  </Button>
                </div>
              </form>
            </div>

            <div className="flex flex-col text-gray-500 justify-between mb-8">
              <p>
                There's nothing quite like the crisp, refreshing taste of an
                ice-cold Coca-Cola. Carefully crafted in 1886, Coca-Cola's great
                taste has stood the test of time. This soda is the perfect
                companion for both special moments and everyday enjoyment. Each
                sip of Coca-Cola's signature taste brings a smile. This
                refreshing soft drink is meant to be enjoyed anytime, anywhere,
                making life's moments more magical. Coca-Cola's secret formula
                has been carefully crafted to create a taste experience like no
                other. The familiar fizz and caffeine give you an instant "ahh"
                moment with every sip. Pop open a Coca-Cola and drink in the
                moments that make life special. Whether spending time with loved
                ones or taking a quick break to recharge, Coca-Cola makes the
                ordinary extraordinary. Refreshing the world for over 130 years,
                Coca-Cola continues to bring people together and spread optimism
                wherever it goes. Find your magic and make life delicious with
                an ice-cold Coca-Cola.
              </p>
            </div>
          </div>
        </div>
      )}

      {!data.pour.detail.id && <Loader />}
    </>
  );
};

export default Pour;
