import React from "react";
import { ThreeDots } from "react-loader-spinner";

const Loader = () => {
  return (
      <div className="flex flex-col justify-center align-middle items-center w-full">
          <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#444"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
          />
    </div>
  );
};

export default Loader;
