import React, {useEffect} from "react";

import {setList as cartSetList} from "../redux/Cart";
import {setList as pourSetList} from "../redux/Pour";
import {useDispatch} from "react-redux";

const BaseAPI = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/getAllByUser/4')
            .then(data => data.json())
            .then(data => {
                    dispatch(cartSetList([data.cart]));
                    dispatch(pourSetList(data.pours));
                }
            );
    }, []);


    return (
        <>

        </>
    );

}

export default BaseAPI;
