import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
export interface TransactionState {
    items: any,
    activeItem: any,
    request: number,
}

const initialState: TransactionState = {
    items: [],
    activeItem: [],
    request: 0
};

export const Transaction = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        addTransaction: (state, action: PayloadAction<any>) => {
            state.items.push(action.payload);
        },
        addActiveTransaction: (state, action: PayloadAction<any>) => {
            state.activeItem = action.payload;
        },
        clearData : (state) => {
            state.items = [];
            state.activeItem = [];
        },
        requestStart : (state) => {
            state.request = 1;
        },
        requestEnd : (state) => {
            state.request = 0
        }
    },
})

export const { addTransaction,addActiveTransaction,clearData,requestStart,requestEnd } = Transaction.actions

export default Transaction.reducer
