import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Loader from "..//Loader";
import { useNavigate } from "react-router-dom";
import { clearData } from "../../redux/Transaction";
import { useDispatch } from "react-redux";
import Button from "../Button";
import { getData } from "../User/TransactionsData";

export default function CheckoutForm(props: any) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [button, setButton] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [messageType, setMessageType] = useState(""); // New state for message type

  const addTransaction = async (transaction: any) => {
    fetch(process.env.REACT_APP_API_URL + "/transaction/add", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(transaction),
    })
      .then((res) => res.json())
      .then(() => {
        dispatch(clearData());
      });
  };

  const moveToOrder = () => {
    getData(dispatch);
    navigate("/order");
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      setShowForm(true);
      setShowLoader(false);
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then((output: any) => {
      switch (output.paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setMessageType("success");

          let order = props.data.order;
          let transaction: any = {};

          transaction.stripe_id = output.paymentIntent.id;
          transaction.amount = output.paymentIntent.amount;
          transaction.totalPrice = order.totalPrice;
          transaction.tax = order.totalTax;
          transaction.unitPrice = order.totalTax;
          transaction.pouts_id = order.items[0].id;
          transaction.qty = order.items[0].qty;
          transaction.customer_id = localStorage.getItem("customer_id");

          addTransaction(transaction);

          localStorage.setItem("order", "");

          setButton("ok");

          setShowForm(false);
          setShowLoader(false);
          break;
        case "processing":
          setShowLoader(false);
          setMessage("Your payment is processing.");
          setMessageType("info");
          break;
        case "requires_payment_method":
          setShowLoader(false);
          setMessage("Your payment was not successful, please try again.");
          setMessageType("error");
          break;
        default:
          setShowLoader(false);
          setMessage("Something went wrong.");
          setMessageType("error");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const error: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: process.env.REACT_APP_URL + "/stripe",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setShowLoader(false);
      setMessage(error.message);
      setMessageType("error");
    } else {
      setShowLoader(false);
      setMessage("An unexpected error occurred.");
      setMessageType("error");
    }

    setIsLoading(false);
  };

  return (
    <div className="space-y-6 mt-12">
      <h4 className="text-2xl font-semibold text-gray-900 ">Payment</h4>
      {showForm && (
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />

          <div className="flex items-start sm:items-center my-4">
            <input
              id="terms-checkbox-2"
              type="checkbox"
              value=""
              className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-primary-600 focus:ring-2 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600"
            />
            <label
              htmlFor="terms-checkbox-2"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              {" "}
              I agree with the{" "}
              <a
                href="#"
                title=""
                className="text-primary-700 underline hover:no-underline dark:text-primary-500"
              >
                Terms and Conditions
              </a>{" "}
              of use of the marketplace{" "}
            </label>
          </div>

          <div className="gap-4 sm:flex sm:items-center">
            <button
              className="mt-4 flex w-full items-center justify-center rounded-lg bg-dark-blue max-h-11 px-5 py-2.5 text-md font-bold text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:mt-0"
              disabled={isLoading || !stripe || !elements}
              id="submit"
            >
              <span id="button-text">{isLoading ? <Loader /> : "Pay now"}</span>
            </button>
            {/* Show any error or success messages */}
          </div>
        </form>
      )}

      {showLoader && <Loader />}

      {message && (
        <div
          id="payment-message"
          className={`flex justify-center text-lg font-semibold p-2 rounded-md ${
            messageType === "error" ? "bg-red" : "bg-green"
          }`}
        >
          {message}
        </div>
      )}

      {button == "ok" && (
        <>
          <Button style="primary" type="button" onClick={moveToOrder}>
            Continue
          </Button>
        </>
      )}
    </div>
  );
}
