import React, { useEffect, useState } from "react";

import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaravan } from "@fortawesome/free-solid-svg-icons";
import type { RootState } from "../Store";
import { selectCart } from "../redux/Cart";
import Qrscan from "../components/Qrscan";
import DemoCustomVideoFilter from "../components/Qrscan";
import Button from "../components/Button";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state: RootState) => state);

  const clickMe = (parameter: { id: null; name: ""; price: 0 }) => () => {
    dispatch(selectCart(parameter));
    navigate("/cart/" + parameter.id);
  };

  if (data.cart.detail.length != 0 && data.pour.detail.length != 0) {
    navigate("/cart/" + data.cart.detail.id + "/pour/" + data.pour.detail.id);
  }

  if (data.cart.detail.length != 0) {
    navigate("/cart/" + data.cart.detail.id);
  }

  if (data.cart.detail.length != 0 && data.pour.detail.length != 0) {
    navigate("/cart/" + data.cart.detail.id + "/pour/" + data.pour.detail.id);
  }

  if (data.cart.detail.length != 0) {
    navigate("/cart/" + data.cart.detail.id);
  }

  return (
      <>
        <Qrscan/>
      </>
  );
};

export default Home;
