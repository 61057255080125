import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
export interface CartState {
    items: any,
    totalPrice: number,
    totalTax: number,
}

const initialState: CartState = {
    items: [],
    totalPrice: 0,
    totalTax: 0
};

export const Order = createSlice({
    name: 'order',
    initialState,
    reducers: {
        addCart: (state, action: PayloadAction<any>) => {
            state.items.push(action.payload);
            state.totalPrice += action.payload.taxPrice;
            state.totalPrice += action.payload.price;
            state.totalTax += action.payload.taxPrice;
            localStorage.setItem('order',JSON.stringify(state));
        },
        setCart: (state, action: PayloadAction<any>) => {

            state.items = action.payload.items;
            state.totalPrice = action.payload.totalPrice;
            state.totalTax = action.payload.totalTax;

        }
    },
})

// Action creators are generated for each case reducer function
export const { addCart,setCart } = Order.actions

export default Order.reducer
