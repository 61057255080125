import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {CartState} from "./Order";

export interface WebsocketState {
    messages: any,
    last: any,
    connected: boolean
}

const initialState: WebsocketState = {
    messages: [],
    last: [],
    connected: false
};

const Websocket = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        websocketMessageReceived: (state, action: PayloadAction<any>) => {
            state.messages.push(action.payload);
            state.last = action.payload;
        },
        websocketConnected: (state) => {
            state.connected = true;
        },
        websocketDisconnected: (state) => {
            state.connected = false;
        }
    }
});

export const {
    websocketMessageReceived,
    websocketConnected,
    websocketDisconnected
} = Websocket.actions;

export default Websocket.reducer;
