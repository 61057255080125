import React, {useEffect, useState} from "react";

import {getData} from "./TransactionsData";
import {useDispatch} from "react-redux";

const User = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        if (localStorage.getItem('customer_id')) {

            getData(dispatch);

        } else {

            fetch(process.env.REACT_APP_API_URL + '/addCustomer', {
                method: "POST",
                headers: {"Content-Type": "application/json"}
            })
                .then(data => data.json())
                .then(data => {
                        localStorage.setItem('customer_id', data.customer_id)
                    }
                );

        }

    }, []);

    return (<></>);

}

export default User;
