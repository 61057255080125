
import {addActiveTransaction, addTransaction,clearData,requestEnd,requestStart} from "../../redux/Transaction";

export const getData = async (dispatch:any) => {

    dispatch(clearData());
    dispatch(requestStart());

    fetch(process.env.REACT_APP_API_URL + '/transaction/customer/' + localStorage.getItem('customer_id'))
        .then(data => data.json())
        .then(data => {

                data.transactions.map((transaction: any, index: number) => {
                    if(transaction.qtyUsed == transaction.qty) {
                        dispatch(addTransaction(transaction));
                    }else{
                        dispatch(addActiveTransaction(transaction));
                    }
                });

                dispatch(requestEnd());
            }
        );
}
