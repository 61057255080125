import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import "./index.css";

import BaseAPI from "./components/BaseAPI";
import Stripe from "./components/Stripe";
import User from "./components/User";

import Home from "./pages/Home";
import Pour from "./pages/Pour";
import Cart from "./pages/Cart";
import Order from "./pages/Order";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Info from "./pages/Info";

function App() {
  return (
    <Router>

      {/* <Install/> */}
      <User />
      <BaseAPI />
      <Header />
      <Footer></Footer>
      {/* <Navigation/> */}

      <main className="z-0 overflow-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/order" element={<Order />} />
          <Route path="/cart/:id" element={<Cart />} />
          <Route path="/cart/:id/pour/:pid" element={<Pour />} />
          <Route path="/stripe" element={<Stripe />} />
          <Route path="/info" element={<Info />} />
        </Routes>
      </main>

    </Router>
  );
}

export default App;
