
import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './redux/CounterSlice'
import cartReducer from './redux/Cart'
import orderReducer from './redux/Order'
import pourReducer from './redux/Pour'
import transactionReducer from "./redux/Transaction";
import websocketReducer from "./redux/WebSocket"

export const Store = configureStore({
    reducer: {
        counter: counterReducer,
        cart: cartReducer,
        order: orderReducer,
        pour: pourReducer,
        transaction: transactionReducer,
        websocket: websocketReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch
